import React from 'react';
import {useIntl} from 'react-intl';
import Tooltip from 'antd/lib/tooltip';

export function Detail (props:{
    type:string,
    name?:string,
    value:string,
    goTo?:Function,
    selected:boolean
    disabled?:boolean
}) {
    const intl = useIntl();
    let detail:JSX.Element = <></>;


    let style:any = {
        float: 'left',
        width: ' 2.4rem',
        height: '2.4rem',
        cursor: 'pointer',
        marginLeft: '5px',
        marginBottom: '5px',
        borderRadius: '50%'
    };

    switch (props.type){
        case 'color':
            if (props.value) {
                if (props.selected) {
                    style['border'] = '3px solid #ffffff';
                    style['display'] = 'flex';
                    style['justify-content'] = 'center';
                    style['align-items'] = 'center';
                }

                style['backgroundColor'] = props.value;

                let tooltipTitle = 'Color' + (props.name ? (': ' + props.name) : '');
                if (props.disabled) {
                    style['opacity'] = '0.3';
                    tooltipTitle = intl.formatMessage({id:'CASHIER_STOCK_DETAIL__LABEL_UNAVAILABLE_PRODUCT'});
                }

                detail = <Tooltip
                    key={props.value}
                    color={props.value}
                    title={tooltipTitle}
                >
                    <div
                        style={style}
                        key={`color-${props.value}`}
                        onClick={() => {
                            if (!props.disabled) {
                                props.goTo&&props.goTo({[props.type]:props.value}, props.value)
                            }
                        }}
                    />
                </Tooltip>
            }
        break

        case 'size':
            if (props.value) {
                if (props.selected) {
                    style['border'] = '1px solid #bfbfbf';
                }

                style['paddingTop'] = '5px';
                style['textAlign'] = 'center';

                let tooltipTitle = intl.formatMessage({id:'CASHIER_STOCK_DETAIL__LABEL_SIZE'}) + (props.name ? (': ' + props.name) : '');

                detail = <Tooltip
                    key={props.value}
                    color={props.value}
                    title={tooltipTitle}
                >
                    <div
                        style={style}
                        className={'sizeCircle'}
                        key={`size-${props.value}`}
                        onClick={() => props.goTo&&props.goTo({[props.type]:props.value}, props.value)}
                    >
                        {props.value.toUpperCase()}
                    </div>
                </Tooltip>
            }
            break

        default:
            detail = <></>;
    }

    return detail
}