import React from 'react';
import {Detail} from './Detail';
import {useIntl} from 'react-intl';

export function Details (props: {
    goTo?:any,
    prototype:any,
    currentKey:any,
    properties:any,
    detailModel?:any,
}) {
    const intl = useIntl();
    let slides = 0;
    let mapSlidesInterface:any = {};

    const drawDetailsProduct = (type:string, value:any, name:string, key:string) => {
        if (!mapSlidesInterface[type]) {
            mapSlidesInterface = Object.assign(mapSlidesInterface, {[type]: {}});
        }

        mapSlidesInterface[type] = Object.assign(mapSlidesInterface[type], {
            [value]: slides
        });

        slides++;
        let disabled = false;
        let selected = (props.properties[type] == value);
        if (props.properties['size'] && props.properties['color'] && type === 'color') {
            const sizeProp = props.properties['size'];
            const detailMod = props.detailModel[sizeProp];
            disabled = !detailMod.includes(value);
        }

        return <Detail
            key={key}
            type={type}
            name={name}
            value={value}
            goTo={props.goTo} selected={selected} disabled={disabled}
        />
    }

    let sizeName = '';
    let colorName = '';
    if (props.properties['size']) {
        props.prototype['size'].map((current: any) => {
            if (current.value === props.properties['size'] && current.name) {
                sizeName = (intl.formatMessage({id:'CASHIER_STOCK_DETAIL__LABEL_SIZE'}) + ' - ' + current.name);
            }
        });
    }

    if (props.properties['color']) {
        props.prototype['color'].map((current: any) => {
            if (current.value === props.properties['color'] && current.name) {
                colorName = (sizeName != '' ? '|' : '') + (' Color - ' + current.name);
            }
        });
    }

    return (
        <div className={'Cashier-item-draw-details-content'}>
            <div className={'Cashier-props-detail secondaryTXT'}>
                {sizeName} {colorName}
            </div>

            {Object.keys(props.prototype).map((property: string) => {
                slides = 0;
                if (property == 'color' || property == 'size') {
                    return (
                        <div key={`${props.currentKey}-${property}`} className={'Cashier-item-draw-details'}>
                            {
                                props.prototype[property].find(e=>e.value==="Talla Única")&& props.prototype[property].filter(e=>e.value!=="Talla Única").length===0 ?
                                  <p style={{fontWeight:600}}>TALLA ÚNICA</p>
                                  :
                                props.prototype[property].filter(e=>e.value!=="Talla Única").map((prop:any, index:number) => {
                                    return drawDetailsProduct(property, prop.value, prop.name, `${props.currentKey}-${property}-${index}`)
                                })
                            }
                        </div>
                    )
                }

                return <></>;
            })}</div>
    )
}