import './MaxAddAndRemoveProduct.css';
import {PlusOutlined, MinusOutlined} from '@ant-design/icons';
import {Context} from '../../Store';
import React, {useContext} from 'react';
import {Button, Icon} from 'react-materialize';
import {ProductInterface} from '../../product/ProductInterface';

export function MaxAddAndRemoveProduct (props:{
  message:string,
  cart?: boolean,
  product:ProductInterface
}) {
  const [state, dispatch] = useContext(Context);
  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  const getProductById = (id:string) => {
    for (const product of state.products[state.merchantId]) {
      if(product.models) {
        for (const currentProduct of product.models) {
          if (currentProduct.id == id) {
            return currentProduct;
          }
        }
      }
      else {
        if (product.id == id) {
          return product;
        }
      }
    }
  }


  const addProductToCart = (id:string) => {
    const product = getProductById(id);
    if (Object.keys(product).length) {
      const quantity = [product.id] ? cart[product.id] + 1 : 1;

      dispatch({
        type: 'ADD-CARD',
        payload: {
          [id]: quantity
        }
      });
    }
  };

  const removeProductToCart = (id:string) => {
    const product = getProductById(id);
    if (Object.keys(product).length) {
      const quantity = cart[product.id] - 1;
      dispatch({
        type: 'ADD-CARD',
        payload: {
          [id]: quantity
        }
      });
    }
  };

  return (
    !cart?
    <div id={`Cashier-item-adding-${props.product.id}`} className={'Max-header-cart-modal-product-addg'}>
      <Button
        floating
        waves={'light'}
        className={'max-cart-add-but'}
        disabled={!cart[props.product.id]}
        onClick={() => removeProductToCart(props.product.id)}
        icon={<MinusOutlined className={'max-cart-add-but'}/>}
      />

      <p className={'Max-cart-quantity'}>
        {
          props.message == "" ?
            cart[props.product.id]
            :
            props.message}
      </p>

      <Button
        floating
        waves={'light'}
        className={'max-cart-add-but'}
        onClick={() => addProductToCart(props.product.id)}
        icon={<PlusOutlined    className={'max-cart-add-but'}/>}
        disabled={cart[props.product.id] == props.product.quantity || props.product.id == state.ruleParams.idProductShipping}
      />
    </div>
      :
      <div className={'Max-cart-control-cont2'}>
      <div className={'Max-cart-control-cont'}>
        <MinusOutlined onClick={() => removeProductToCart(props.product.id)} className={'Max-cart-control-icon'} />
        <p className={'Max-cart-control-text'}> {

              cart[props.product.id]
            }</p>
        <PlusOutlined  onClick={() => {
          props.product.quantity>cart[props.product.id]&&
          addProductToCart(props.product.id);
        }} className={'Max-cart-control-icon'}/>
      </div>
        {!props.cart?<p className={'Max-cart-control-text2'}> {
          props.message}</p>:<></>}
        </div>
  )
}
