import React from 'react';
import {FormattedMessage, } from 'react-intl';
import {Utils} from '../../services/Utils.class';
import logos from '../../static/logos/logos_img.json';

export function FooterCashier(props: {
    state:any,
    fac?:boolean
}) {
    return (
        <footer id={'global-footer'}>
            <div className={'footer-content'}>
                <div className={'footer-section'}>
                    <div className={'footer-shop'}>
                        <FormattedMessage id={'FOOTER_CASHIER__LABEL_BUYING_METHODS_TITLE'}/>
                        <a
                          href={''}
                          data-analytics-title={'find an store'}
                          onClick={() => Utils.openNewTab(props.state.currentMerchant.links.myStore)}
                        > <FormattedMessage id={'FOOTER_CASHIER__BUTTON_VIEW_STORE'}/>. </a>
                        <span className={'nowrap'}> <FormattedMessage id={'FOOTER_CASHIER__LABEL_CONTACT_NUMBER'}/> {props.state.currentMerchant.phoneNumber}.</span>
                    </div>
                    <div className={'footer-logos'}>
                        <img
                          alt={'mastercard'}
                          className={'card-img-footer'}
                          src={`data:image/png;base64, ${logos.mc_id_check}`}
                        />
                        {props.fac &&
                            <img
                                alt={'fac'}
                                className={'card-img-footer'}
                                src={`data:image/png;base64, ${logos.powered_by_fac}`}
                            />
                        }
                        <img
                          alt={'visa'}
                          className={'card-img-footer'}
                          src={`data:image/png;base64, ${logos.visa_secure}`}
                        />
                    </div>
                    <div className={'footer-legal'}>
                        <div className={'footer-legal-copyright'}>
                            © {Utils.getDate().getFullYear()} {(props.state.currentMerchant.id) ? Utils.capitalize(props.state.currentMerchant.id) : ''}.
                            <FormattedMessage id={'FOOTER_CASHIER__LABEL_RIGHTS_RESERVED'}/>
                        </div>
                        <div className={'footer-legal-links'}>
                            <a
                              className={'footer-legal-link'}
                              href={''} data-analytics-title={'Términos de uso'}
                              onClick={() => Utils.openNewTab(props.state.currentMerchant.links.termsUse)}
                            ><FormattedMessage id={'FOOTER_CASHIER__BUTTON_TERMS'}/></a>
                            <a
                              href={''}
                              className={'footer-legal-link'}
                              data-analytics-title={'Ventas y reembolsos'}
                              onClick={() => Utils.openNewTab(props.state.currentMerchant.links.salesRefund)}
                            ><FormattedMessage id={'FOOTER_CASHIER__BUTTON_REFUNDS'}/></a>
                            <a
                              href={''}
                              className={'footer-legal-link'}
                              data-analytics-title={'Politicas de privacidad'}
                              onClick={() => Utils.openNewTab(props.state.currentMerchant.links.privacyPolicy)}
                            ><FormattedMessage id={'FOOTER_CASHIER__BUTTON_PRIVACY'}/></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
